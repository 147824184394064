import React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import '../GlobalEduCare/GlobalEduCare.css'
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Link } from 'react-router-dom';
import boss from './Boss.jpg';
import CarouselForAnProperty from './CarouselForAnProperty.js/CarouselForAnProperty';
const AnProperty = () => {
    return (




        <div>
            <div className='eduCareContainer container'>



                <Grid container spacing={2} className='row align-items-center container forBG'>
                    <section item className='col-md-5'>
                        <div className='text-center'>
                            <img className='responsive my-5' src={boss} alt="" />
                        </div>
                    </section>
                    <section item className='col-md-7'>
                        <div >

                            <div>
                                <Box>
                                    {/* <h2 className="ceo-title"> Managing Director at ANProperty </h2>
                                    <strong>Jan 2023 - Present</strong> */}






                                    <div class="center-wrap ceo-title">
                                        <div class="title-container">
                                            <div class="ribbon-left"></div>
                                            <div class="backflag-left"></div>
                                            <div class="title"><a href="/">Managing Director at ANProperty</a></div>
                                            <div class="backflag-right"></div>
                                            <div class="ribbon-right"></div>
                                        </div>
                                    </div>





                                </Box>
                                <Box>
                                    <p><strong>Jan 2023 - Present</strong></p>
                                    <p>
                                        As a managing director in AN Property, my role entails strategic planning, operational oversight, financial management, business development, deal negotiation, and ensuring regulatory compliance. I'll also be leading teams, building strong client relationships, and staying updated on industry trends to make informed decisions. My goal is to provide top-notch service and contribute to the well-planned urbanization of Sylhet. Let's make it happen!
                                    </p>
                                </Box>
                                <Box>
                                    Visit Us : <Link to="https://anpropertybd.com" target="blank"> <span className='email'>anpropertybd.com</span> </Link>
                                    <Box className="edu-care-social-media">
                                        <strong>Follow Us On :</strong>
                                        <Link to="https://www.facebook.com/anpropertybd" target='blank'> <i class="fab fa-facebook"></i> </Link>
                                        <Link to="https://www.youtube.com/@AnProperty" target='blank'> <i class="fab fa-youtube"></i> </Link>
                                        <Link to="https://www.instagram.com/anpropertybd/"><i class="fab fa-instagram"></i></Link>
                                    </Box>
                                </Box>
                            </div>

                        </div>
                    </section>

                </Grid>

















            </div>
            <div>
                <CarouselForAnProperty />
            </div>
        </div>



    );
};

export default AnProperty;