import React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import './GlobalEduCare.css';
import boss2 from './boss.png';
import { Link } from 'react-router-dom';
import MyCarousel from './ImageGallery/MyCarousel';



const GlobalEduCare = () => {
    return (
        <div>

            <div className='eduCareContainer container'>

                <Grid container spacing={2} className='row align-items-center container forBG'>
                    <section item className='col-md-5'>
                        <div className='text-center'>
                            <img className='responsive my-5' src={boss2} alt="" />
                        </div>
                    </section>
                    <section item className='col-md-7'>
                        <div >

                            <div>
                                <div class="center-wrap ceo-title">
                                    <div class="title-container">
                                        <div class="ribbon-left"></div>
                                        <div class="backflag-left"></div>
                                        <div class="title"><a href="/">CEO at Global Education Care</a></div>
                                        <div class="backflag-right"></div>
                                        <div class="ribbon-right"></div>
                                    </div>
                                </div>
                                <Box>
                                    <p><strong>Jan 2012 - Present</strong></p>
                                    <p className='forText'>Education Care Bangladesh I have been managing from the very beginning as the founding director.I have been working Overseas Student University Admission Consultancy for over 12 years.
                                        I believe that students from developing countries like Bangladesh, India, Nepal, and Sri Lanka, often referred to as the Third World, should pursue higher education abroad and, upon completing their studies, return to our countries to contribute to their development. This way, students can enhance the progress of our nations and neighboring developing countries. For this reason, I believe those who have the capability and competence should definitely pursue higher education abroad, and I encourage them to do so. Many students, after completing their graduation from prestigious universities worldwide, have established themselves and some have returned to their home countries, working in reputable institutions.
                                    </p>
                                </Box>
                                <Box>
                                    Visit Us : <Link to="https://gecare.co.uk/" target="blank"> <span className='email'>https://gecare.co.uk</span> </Link>
                                    <Box className="edu-care-social-media">
                                        <strong>Follow Us On :</strong> <Link to="https://www.facebook.com/globaleducationcareltd" target='blank'> <i class="fab fa-facebook"></i> </Link>  <Link to="https://www.linkedin.com/company/education-care/" target='blank'> <i class="fab fa-linkedin"></i> </Link>
                                    </Box>
                                </Box>
                            </div>

                        </div>
                    </section>

                </Grid>

            </div>
            <div>
                <MyCarousel />
            </div>
        </div>
    );
};

export default GlobalEduCare;