import React, { useState } from 'react';
import './HomeNavBar.css';
import { NavLink } from 'react-router-dom';
import { HamburgMenuClose, HamburgMenuOpen } from './Icon';
import flower from './Untitled-1.png';
import EidMobarok from './name.png'

const HomeNavBar = () => {
    const [click, setClick] = useState(false);

    const handleClick = () => setClick(!click);
    return (
        <div>
            <div className='row' style={{width: "50% !important"}}>
                <div className='col-12'>
                <img src={flower} alt="" className='image-name' />
                </div>
            </div>
            <nav className="navbar">

                <div className="nav-container">
                    <div>
                        <NavLink exact to="/" className="nav-logo">
                            <img className='nameByImg' src={EidMobarok} alt="Numan" />
                        </NavLink>
                    </div>

                    <ul className={click ? "nav-menu active" : "nav-menu"}>
                        <li className="nav-item">
                            <NavLink
                                exact
                                to="/"
                                activeclassname="active"
                                className="nav-links"
                                onClick={handleClick}
                            >
                                Home
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink
                                exact
                                to="/about"
                                activeclassname="active"
                                className="nav-links"
                                onClick={handleClick}
                            >
                                About
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink
                                exact
                                to="/travels"
                                activeclassname="active"
                                className="nav-links"
                                onClick={handleClick}
                            >
                                Travels
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink
                                exact
                                to="/blog"
                                activeclassname="active"
                                className="nav-links"
                                onClick={handleClick}
                            >
                                Blog
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink
                                exact
                                to="/club-story"
                                activeclassname="active"
                                className="nav-links"
                                onClick={handleClick}
                            >
                               Social Club Activities
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink
                                exact
                                to="/contact"
                                activeclassname="active"
                                className="nav-links"
                                onClick={handleClick}
                            >
                                Contact Me
                            </NavLink>
                        </li>
                    </ul>
                    <div className="nav-icon" onClick={handleClick}>

                        {click ? (
                            <span className="icon">
                                <HamburgMenuClose />
                            </span>

                        ) : (
                            <span className="icon">
                                <HamburgMenuOpen />{" "}
                            </span>
                        )}
                    </div>
                </div>
            </nav>
        </div>
    );
};

export default HomeNavBar;
