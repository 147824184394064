import one from "../images/Travels/one.jpg";
import two from "../images/Travels/two.jpg";
import three from "../images/Travels/three.jpg";
import four from "../images/Travels/four.jpg";
import five from "../images/Travels/five.jpg";
import six from "../images/Travels/six.jpg";
import seven from "../images/Travels/seven.jpg";
import eight from "../images/Travels/eight.jpg";
import zero from '../images/Travels/zero.jpg';
import zeroPointOne from '../images/Travels/zeroPontOne.jpg';
import zeroPointTwo from '../images/Travels//zeroPonitTwo.jpg';

export const imagesFolder = [

  {
    "img": zero,
    "alt": "Image 00"
  },
  {
    "img": zeroPointOne,
    "alt": "Image 0.1"
  },
  {
    "img": zeroPointTwo,
    "alt": "Image 0.2"
  },
  {
    "img": one,
    "alt": "Image 01"
  },
  {
    "img": two,
    "alt": "Image 02"
  },
  {
    "img": three,
    "alt": "Image 03"
  },
  {
    "img": four,
    "alt": "Image 04"
  },
  {
    "img": five,
    "alt": "Image 05"
  },
  {
    "img": six,
    "alt": "Image 06"
  },
  {
    "img": seven,
    "alt": "Image 07"
  },
  {
    "img": eight,
    "alt": "Image 08"
  }
];