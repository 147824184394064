import one from "../images/EduCare/Alberta-Newst-Polytecnic-.jpg";
import two from "../images/EduCare/Algoma-.jpg";
import three from "../images/EduCare/Canada-west-.jpg";
import four from "../images/EduCare/Macewan-.jpg";
import five from "../images/EduCare/Manitoba.jpg";
import six from "../images/EduCare/Trw-1.jpg";
import seven from "../images/EduCare/Windsor.jpg";
import eight from "../images/EduCare/Yourk-ville.jpg";

export const EduCare = [
    {
      "img": one,
    },
    {
      "img": two,
    },
    {
      "img": three,
    },
    {
      "img": four,
    },
    {
      "img": five
    },
    {
      "img": six
    },
    {
      "img": seven
    },
    {
      "img": eight
    }
  ]