import * as React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typewriter from 'typewriter-effect';
import './HomePage.css'
import { Button } from '@mui/material';
import { Link } from 'react-router-dom';
import pic from '../HomePage/homeProfessional.jpg';
import cut from './cut.png';
import modalImg from './abdulla al numan eid greertings.jpg'
import Modal from '@mui/material/Modal';


export default function HomePage2() {

  const [open, setOpen] = React.useState(true);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [show, setShow] = React.useState(false)
  return (





    <div>
      {/* <div>
        <Modal id='popUpMain' style={{ display: "none" }}
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          className='text-center EidImag'
        >
          <img
           height={400}
            src={modalImg}
            alt="imagemodal"
          />
        </Modal>
      </div> */}
      <Grid container spacing={2} className='row container mt-4 mb-3 m-auto'>
        <section item className='col-md-6 left'>
          <div >

            <div className='text-center'>
              <img src={pic} className='responsive2' alt="" />
            </div>

          </div>
        </section>
        <section item className='col-md-6 right' id='background'>
          <div>

            <Box
              sx={{
                my: 3,
                mx: 4,
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <h3 className='name'>
                Rtn. Abdullah Al <span>Numan</span>
              </h3>
              <h6 className='typewriter'>

                <Typewriter

                  onInit={(typewriter) => {
                    typewriter
                      .typeString("President of Rotary Club of Sylhet Holy Land(2024-25).")
                      .start()
                  }
                  }
                  options={{
                    autoStart: true,
                  }}

                />
                <Typewriter

                  onInit={(typewriter) => {
                    typewriter
                      .typeString("CEO at Global Education Care Ltd.")
                      .start()
                  }
                  }
                  options={{
                    autoStart: true,
                  }}

                />
                <Typewriter

                  onInit={(typewriter) => {
                    typewriter
                      .typeString("Managing Director at AN Property.")
                      .start()
                  }
                  }
                  options={{
                    autoStart: true,
                  }}

                />
                <Typewriter

                  onInit={(typewriter) => {
                    typewriter
                      .typeString('Directorship partner of "The Sylhet Gym"')
                      .start()
                  }
                  }
                  options={{
                    autoStart: true,
                  }}

                />
                <div className='forWelcome'>
                  <Typewriter
                    onInit={(typewriter) => {
                      typewriter
                        .typeString("--Welcome You")
                        .start()
                    }
                    }
                    options={{
                      autoStart: true,
                    }}

                  />
                </div>
              </h6>
              <p className='forText'>
                As a multifaceted businessman, I operate in three diverse sectors. Global Education Care Limited serves as an admission consulting firm, guiding students through the process of studying abroad. In the real estate sector, "AN Property" facilitate property transactions by providing a platform for buyers and sellers to connect seamlessly. Additionally, my role as a directorship partner at "The Sylhet Gym" underscores my commitment to promoting health and wellness within the community. Through these ventures, I aim to make a positive impact by empowering individuals to pursue education, find their dream homes, and prioritize their physical well-being.
              </p>
              <p className='homeFollow'><strong>Follow Me On </strong> <span>
                <Link to="https://www.linkedin.com/in/rtn-abdullah-al-numan-1b69a3a9/" target='blank'><i class="fab fa-linkedin"></i></Link>
                <Link to="https://www.facebook.com/abdullahal.numan.07" target='blank'><i class="fab fa-facebook"></i></Link>
                <Link to="https://www.instagram.com/aanuman/" target='blank'><i class="fab fa-instagram"></i></Link>
              </span></p>
              <section className='d-flex'>
                <Link to="about"><Button variant="outlined" className='seeMoreBtn right'>See More</Button></Link>
                <Link to="contact"><Button variant="outlined" className='seeMoreBtn bg-success text-light left'>Contact Me</Button></Link>
              </section>
            </Box>
          </div>
        </section>



      </Grid>
      <img src={cut} alt="" className='bottomImg' />
    </div>


  );
}