import React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import '../GlobalEduCare/GlobalEduCare.css';
import { Link } from 'react-router-dom';
import CarouselForGym from './CarouselForGym/CarouselForGym';
import Gym from './GymImg.jpg'

const TheSylhetGym = () => {
    return (




        <div>

            <div className='eduCareContainer container'>

                <Grid container spacing={2} className='row align-items-center container forBG'>
                    <section item className='col-md-5'>
                        <div className='text-center'>
                            <img className='responsive my-5' src={Gym} alt="" />
                        </div>
                    </section>
                    <section item className='col-md-7'>
                        <div >

                            <div>
                                <Box>
                                    {/* <h3 className="ceo-title"> Directorship Partner of "The Sylhet Gym". </h3>
                                    <strong>Jan 2023 - Present</strong> */}

                                    <div class="center-wrap">
                                        <div class="title-container">
                                            <div class="ribbon-left"></div>
                                            <div class="backflag-left"></div>
                                            <div class="title"><a href="/">Directorship Partner of "The Sylhet Gym"</a></div>
                                            <div class="backflag-right"></div>
                                            <div class="ribbon-right"></div>
                                        </div>
                                    </div>


                                </Box>
                                <Box>
                                    <p> <strong>Jan 2023 - Present</strong></p>
                                    <p>
                                        Sylhet's first air conditioned high quality five star
                                        Gym Center The Sylhet GYM For the convenience of those who cannot go to the gym during the day due to busy work, our gym center will be open till twelve-thirty at night. We do business, study, charity in our daily life, but our fitness should be right. Building a gym center in light of this
                                    </p>
                                </Box>
                                <Box>

                                    <Box className="edu-care-social-media">
                                        <strong>Follow Us On :</strong> <Link to="https://www.facebook.com/thesylhetgym" target='blank'> <i class="fab fa-facebook"></i> </Link>  <Link to="https://www.instagram.com/thesylhetgym/" target='blank'> <i class="fab fa-instagram"></i> </Link>
                                    </Box>
                                </Box>
                            </div>

                        </div>
                    </section>

                </Grid>

            </div>
            <div>
                <CarouselForGym />
            </div>
        </div>




































    );
};

export default TheSylhetGym;