import React from 'react';
import './ClubTimeline.css';
import image1 from '../../component/asset/images/tw2.jpg';
import image3 from '../../component/asset/images/picnic.jpg';
import image4 from '../../component/asset/images/21 February.jpg';
import image5 from '../../component/asset/images/DSC_0116 (1).jpg';
import image6 from '../../component/asset/images/IMG_8615.JPG';
import Travels from '../Travels/Travels';

const ClubTimeline = () => {
    return (
        <div>
            <section id="timeline">
                


                <div class="center-wrap">
                    <div class="title-container">
                        <div class="ribbon-left"></div>
                        <div class="backflag-left"></div>
                        <div class="title"><a href="/">Social Club involvements</a></div>
                        <div class="backflag-right"></div>
                        <div class="ribbon-right"></div>
                    </div>
                </div>





                <p class="leader">As the President(2024-25) of Rotary Club of Sylhet Holy Land, I lead a dynamic portfolio of club activities aimed at fostering global unity and creating lasting positive change. Rotary International clubs often engage in visits and flag exchanges as a way to promote friendship, international understanding, and goodwill among Rotarians worldwide. These visits allow Rotarians from different clubs and countries to connect, share experiences, and collaborate on projects that address global challenges. The flag exchange symbolizes the unity and common purpose of Rotary clubs around the world, emphasizing their commitment to service and making a positive impact in local and international</p>
                <div class="demo-card-wrapper">
                    <div class="demo-card demo-card--step1">
                        <div class="head">
                            <div class="number-box">
                                <span>01</span>

                            </div>
                            <h2><span class="small">Tree</span> Plantation</h2>
                        </div>
                        <div class="body">
                            <p>Rotary Club of Sylhet Holy Land  Organised Tree Plantation 2014,Baluchor, Sylhet.</p>
                            <img src={image5} alt="Graphic" />

                        </div>
                    </div>

                    <div class="demo-card demo-card--step2">

                        <div class="head">
                            <div class="number-box">
                                <span>02</span>
                            </div>
                            <h2><span class="small">Weekly</span>Meeting</h2>

                        </div>
                        <div class="body">
                            <p>Farewell to Senior Rotarian 09/07/2024</p>
                            <img src={image1} alt="Graphic" />
                        </div>

                    </div>

                    <div class="demo-card demo-card--step3">
                        <div class="head">
                            <div class="number-box">
                                <span>03</span>
                            </div>
                            <h2><span class="small"> The </span> Victory Day 2015</h2>
                        </div>
                        <div class="body">
                            <p> Commemorating Bangladesh's triumph and the sacrifices of our heroes, inspiring unity, patriotism, and national pride.</p>
                            <img src={image4} alt="Graphic" />
                        </div>
                    </div>

                    <div class="demo-card demo-card--step4">
                        <div class="head">
                            <div class="number-box">
                                <span>04</span>
                            </div>
                            <h2><span class="small">10th</span>Installation Ceremony 2015</h2>
                        </div>
                        <div class="body">
                            <p>10th Installation Ceremony 2015: A memorable event marking the induction of new leadership and celebrating achievements in service and fellowship.</p>
                            <img src={image6} alt="Graphic" />

                        </div>
                    </div>

                    <div class="demo-card demo-card--step5">
                        <div class="head">
                            <div class="number-box">
                                <span>05</span>
                            </div>
                            <h2><span class="small">Annual</span> Picnic 2016</h2>
                        </div>
                        <div class="body">
                            <p>A day of fun, laughter, and camaraderie, creating cherished memories and strengthening bonds within our community.</p>
                            <img src={image3} alt="Graphic" />

                        </div>
                    </div>
                    {/* <div class="demo-card demo-card--step6">
                        <div class="head">
                            <div class="number-box">
                                <span>06</span>
                            </div>
                            <h2><span class="small">Observance of</span> Rotary Day</h2>
                        </div>
                        <div class="body">
                            <p>Rotary Day: Celebrating service, fellowship, and community impact through global projects, unity, and shared values worldwide. Inspiring positive change.</p>
                            <img src={image2} alt="Graphic" />
                        </div>
                    </div> */}
                </div>
            </section>
            <hr className='my-0' />
            <section>
                <h4 className='text-center mt-5 FlagInHand'>Around the World with a Flag in Hand</h4>
                <p className='container mt-3'>The flag exchange, which is a tradition in Rotary clubs worldwide, carries profound symbolism. Rotary Clubs display their own decorative flags at meetings and events. Rotarians traveling to other countries often take these flags to exchange with the clubs they visit. It signifies not only a commitment to international fellowship but also a shared dedication to humanitarian service.</p>
                <Travels />
            </section>
        </div>
    );
};

export default ClubTimeline;