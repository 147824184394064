import React from 'react';
import { Outlet } from 'react-router-dom';
import HomeNavBar from '../../component/HomeNavBar/HomeNavBar';
import Footer from '../../component/Footer/Footer';

          


const BasicRoute = () => {
    return (

        <div>
            <HomeNavBar />
            <Outlet />
            <Footer />
        </div>

    );
};

export default BasicRoute;