import './ContactWithMe.css';
import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';

const ContactWithMe = () => {
    const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs
            .sendForm(process.env.KEY_1,
                process.env.KEY_2,
                form.current,
                process.env.KEY_3)
            .then((result) => {
                console.log(result.text);
            }, (error) => {
                console.log(error.text);
            });
    }
    return (
        <>
            <h3 className='text-center mt-5 FlagInHand'>I would love to hare from you</h3>
            <div class="container" id='contactWithMe'>

                <form ref={form} onSubmit={sendEmail}>
                    <div class="field" tabindex="1">
                        <label for="from_name">
                            <i class="far fa-user"></i>Your Name
                        </label>
                        <input name="from_name" type="text" placeholder="e.g. john doe" required />
                    </div>
                    <div class="field" tabindex="2">
                        <label for="from_email">
                            <i class="far fa-envelope"></i>Your Email
                        </label>
                        <input name="from_email" type="email" placeholder="email@domain.com" required />
                    </div>
                    <div class="field" tabindex="3">
                        <label for="message">
                            <i class="far fa-edit"></i>Your Message
                        </label>
                        <textarea name="message" placeholder="type here" required></textarea>
                    </div>
                    <button type="submit" variant="outlined" className='mb-3'>Send Me Message</button>

                </form>
            </div>
        </>
    )
}

export default ContactWithMe;