import React from 'react';
import './Footer.css';
import { Link } from 'react-router-dom';

const Footer = () => {
    return (

        <footer className='footer'>

            <div class="top">

                <div >
                    <h4 className=''><span>Visit</span> Office</h4>
                    <div class="social2">
                        <li>Sylco Tower, Level-3,</li>
                        <li>Jail Road Point,</li>
                        <li>Sylhet, Bangladesh</li>
                        <li>+880 1753-111777</li>
                    </div>
                </div>
                <div >
                    <h4 className=''>Connect With Me</h4>
                    <div class="social">
                        <Link to="https://www.linkedin.com/in/rtn-abdullah-al-numan-1b69a3a9/" target='blank'><i class="fab fa-linkedin"></i></Link>
                        <Link to="https://www.facebook.com/abdullahal.numan.07" target='blank'><i class="fab fa-facebook"></i></Link>
                        <Link to="https://www.instagram.com/aanuman/" target='blank'><i class="fab fa-instagram"></i></Link>


                    </div>
                </div>
            </div>


            <div class="info">
                <div class="legal">
                    <a href="#">Terms & Conditions</a><a href="#">Privacy Policy</a>
                </div>
                <div class="copyright">2024 Copyright &copy; Numan</div>
            </div>
        </footer>

    );
};

export default Footer;