import React, { useState } from 'react';
import './Travels.css';
import {imagesFolder} from '../../component/asset/fakedata/travelsImages'



const Travels= () => {
    const [images, setImages] = useState(imagesFolder)
  const [lightboxImage, setLightboxImage] = useState("");
  const [lightboxAltImage, setLightboxAltImage] = useState("");

  const openLightbox = (src, alt) => {
    setLightboxImage(src);
    setLightboxAltImage(alt);
  };

  const closeLightbox = () => {
    setLightboxImage("");
    setLightboxAltImage("");
  };
  console.log(images);
  return (
    <div className='my-5 container'>
      <div className="gallery">
        
        {
        images.map((image, index) => (
          <button
            type="button"
            className="gallery__item"
            key={index}
            onClick={() => openLightbox(image.img)}
          >
            <img className="gallery__image" src={image.img} alt={image.alt} />
          </button>
        ))}
      </div>

      {lightboxImage && (
        <div className="lightbox">
          <button
            type="button"
            className="lightbox__close-button"
            onClick={closeLightbox}
          >
            &times;
          </button>
          <img
            className="lightbox__image"
            src={lightboxImage}
            alt={lightboxAltImage}
          />
          <button
            type="button"
            className="lightbox__bg"
            onClick={closeLightbox}
          />
        </div>
      )}
    </div>
  );
};

export default Travels;