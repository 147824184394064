import one from "../images/New folder/an0.jpg";
import two from "../images/New folder/AnImportant.jpg";
import three from "../images/New folder/nice1.jpg";
import four from "../images/New folder/nice2.jpg";
import five from "../images/New folder/nice3.jpg";
import six from "../images/New folder/nice4.jpg";
import seven from "../images/New folder/nice5.jpg";

export const AnPropertyImg = [
    {
      "img": one,
    },
    {
      "img": two,
    },
    {
      "img": three,
    },
    {
      "img": four,
    },
    {
      "img": five
    },
    {
      "img": six
    },
    {
      "img": seven
    }
  ]