import React, { useEffect, useState } from 'react';
import Gallery from '../Travels/Travels';
import axios from "axios";
import { Link } from 'react-router-dom';
import './Blog.css'
import defaultImg from './devDefault.png'

const Blog = () => {
    const [blogList, setBlogList] = useState([]);


    useEffect(() => {
        axios.get('https://dev.to/api/articles?username=kamrul-just-cse')
            .then(response => {
                const articles = response.data;
                console.log(response);
                setBlogList(articles);
            })
            .catch(error => {
                console.error('Error fetching blog articles:', error);
            });
    }, []);

    const publishDate = (dateString) => {
        const date = new Date(dateString);
        return date.toISOString().split('T')[0];
    };

    // Example date string
    // const originalDate = '2024-04-03T09:01:17Z';
    // const formattedDate = formatDate(originalDate);


    const ParaGraphStyle = {
        WebkitLineClamp: 1,
        WebkitBoxOrient: 'vertical',
        display: '-webkit-box',
        overflow: 'hidden'
    }

    return (
        <div className='my-4 container row'>
            {blogList?.map((item) =>

                <div className='col-md-4'>
                    <div class="card-Container">
                        <div class="card">
                            <div class="card-img-holder">
                                <img src={item.cover_image ? item.cover_image : defaultImg} alt="Blog" />
                            </div>
                            <h6 class="blog-title my-3" style={ParaGraphStyle}>{item.title}</h6>
                            <span class="blog-time">Publish Date :     {publishDate(item.published_at)}</span>
                            <p class="description">
                                {item.description}
                            </p>
                            <div class="options">
                                <div className='me-5'>
                                    <i class="fa fa-thumbs-up me-2"></i>
                                    {item.public_reactions_count}
                                </div>
                                <div >
                                    <Link target='_blank' to={item.url} class="btn">
                                        Read Full Blog
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <h1>{item.title}</h1>
                    <img src="https://media.dev.to/cdn-cgi/image/width=1000,height=500,fit=cover,gravity=auto,format=auto/https%3A%2F%2Fdev-to-uploads.s3.amazonaws.com%2Fuploads%2Farticles%2Ffjbg22xwxmsvbg2m1zb6.png" alt="" />
                    <Link to={item.url} target='blank'>Read More</Link> */}
                </div>
            )}

            {console.log("lol", blogList)}
        </div>
    );
};

export default Blog;
