import React from 'react';
import { Route } from 'react-router-dom';
import {
    createBrowserRouter,
    createRoutesFromElements,
} from "react-router-dom";
import BasicRoute from './root/BasicRoute/BasicRoute';
import SignIn from './Page/SignIn/SignIn';
import ContactWithMe from './Page/ContactWithMe/ContactWithMe';
import About from './Page/About/About';
import Blog from './Page/Blog/Blog';
import HomePage2 from './Page/HomePage/HomePage2';
import ClubTimeline from './Page/ClubTimeline/ClubTimeline';
import Travels from './Page/Travels/Travels';



const RouteJSX = (
  


    <>
        <Route>

            <Route path="/" element={<BasicRoute />}>
                <Route path="/"
                    element={<HomePage2 />}
                />
                <Route path="/sign-in" element={<SignIn />} />
                <Route path="/contact" element={<ContactWithMe />} />
                <Route path="/about" element={<About />} />
                <Route path="/club-story" element={<ClubTimeline />} />
                <Route path="/travels" element={<Travels />} />
                <Route path="/blog" element={<Blog />} />
            </Route>
        </Route>

    </>

);

const routes = createRoutesFromElements(RouteJSX);
const router = createBrowserRouter(routes)
export default router;

