import React from 'react';
import './About.css';
import GlobalEduCare from '../../component/GlobalEduCare/GlobalEduCare';
import AnProperty from '../../component/AnProperty/AnProperty';
import TheSylhetGym from '../../component/TheSylhetGym/TheSylhetGym';




const About = () => {
    const handleDrawer = (e,item) => {
        const elementList = document.getElementsByClassName("drawer-content");
        const drawerItemList = document.getElementsByClassName("drawerItem");
        console.log(elementList);
        // elementList.forEach(element => {
        //     element.classList.remove("active-drawer");
        // });
        for(let element of elementList){
            element.classList.remove("active-drawer");
        }
        for(let element of drawerItemList){
            element.classList.remove("active-link");
        }
        document.getElementById(item).classList.add("active-drawer");
        e.currentTarget.classList.add("active-link")

    }
    return (
        <div className='about'>
            <div className='drawer'>
                <p className='drawerItem active-link' onClick={(e) => handleDrawer(e,"Edu")}>Education Care</p>
                <p className='drawerItem' onClick={(e) => handleDrawer(e,"ANP")}>AN Property</p>
                <p className='drawerItem' onClick={(e) => handleDrawer(e,"Gym")}>The Sylhet Gym</p>
            </div>

            {/* <section className='left drawer-content active-drawer' id='default'>
                <AboutTopSection />
            </section> */}
            <section className='right drawer-content active-drawer' id='Edu'>
                <GlobalEduCare />
            </section>
            <section className='right drawer-content' id='ANP'>
                <AnProperty />
            </section>
            <section className='right drawer-content' id='Gym'>
                <TheSylhetGym />
            </section>
        </div>
    );
};

export default About;