import React, { createContext, useEffect, useState } from 'react';
// import { createUserWithEmailAndPassword, getAuth, signInWithEmailAndPassword, signInWithPopup, onAuthStateChanged, signOut } from 'firebase/auth';
// import app from '../../FireBaseAuthentication/firebase.config';

export const AuthContext = createContext();
// const auth = getAuth(app)
const AuthProvider = ({ children }) => {

    const [loggedUser, setLoggedUser] = useState(null);
    const [loading, setLoading] = useState(true);


    // const createUser = (email, password) => {
    //     setLoading(true);
    //     return createUserWithEmailAndPassword(auth, email, password);
    // }


    // useEffect(() => {
    //     const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
    //         setLoggedUser(currentUser);
    //         console.log('user observing', currentUser);
    //         setLoading(false);
    //     });
        
    //     return () => unsubscribe();
    // }, [])

    // const signIn = (email, password) => {

    //     setLoading(true);
        
    //     return signInWithEmailAndPassword(auth, email, password);
    // }


    // const googleSignIn = (provider) => {

    //     setLoading(true);
    //     return signInWithPopup(auth, provider);
    // }
    // const logOut = () => {
    //     return signOut(auth)
    // }
    


    // const authInfo = {
    //     loggedUser,
    //     loading,
    //     createUser,
    //     signIn,
    //     googleSignIn,
    //     logOut,
    // }

    
    // value={authInfo}
    
    return (
        <AuthContext.Provider>
            {children}
        </AuthContext.Provider>
    );
};

export default AuthProvider;