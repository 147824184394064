import one from "../images/gym/gym.jpg";
import two from "../images/gym/gym1.jpg";
import three from "../images/gym/gym2.jpg";
import four from "../images/gym/gym3.jpg";
import five from "../images/gym/gym4.jpg";
import six from "../images/gym/gym5.jpg";
import seven from "../images/gym/gym6.jpg";
import eight from "../images/gym/gym8.jpg";

export const GymImg = [
    {
      "img": one,
    },
    {
      "img": two,
    },
    {
      "img": three,
    },
    {
      "img": four,
    },
    {
      "img": five
    },
    {
      "img": six
    },
    {
      "img": seven
    },
    {
      "img": eight
    }
  ]