import React from 'react';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import {AnPropertyImg} from '../../../component/asset/fakedata/fakeForAnProperty'

const CarouselForAnProperty = () => {
    return (
        <OwlCarousel
            className="owl-theme"
            loop
            autoplay
            autoplayTimeout={2000}
            autoplayHoverPause
            responsive={{
                0: {
                    items: 1 // Display one item on small screens (width < 600px)
                },
                600: {
                    items: 2 // Display two items on medium screens (600px <= width < 900px)
                },
                1000: {
                    items: 3 // Display three items on larger screens (width >= 900px)
                }
            }}
        >
            {
                AnPropertyImg.map((item) => (
                    <section key={item} className=''>
                        <div className='text-center d-flex justify-content-center'>
                            <img className='responsive3 my-2' src={item.img} alt="" />
                        </div>
                    </section>
                ))
            }

        </OwlCarousel>
    );
};

export default CarouselForAnProperty;
